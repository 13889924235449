import React, { useEffect, useState } from "react"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import firebase from "firebase/app"
import { compact, get } from "lodash"
import "firebase/auth"
import Loader from "react-loader-spinner";
import plantSaplings from "assets/images/donations-giting/plantaSapling.svg";
import TrackSaplings from "assets/images/donations-giting/trackaSapling.svg";
import { navigate } from "gatsby"
import config from 'assets/config';

const configData = {
  apiKey: config.srcmApiKey,
  authDomain: config.srcmAuthDomain,
  projectId: config.srcmProjectId,
  storageBucket: config.srcmStorageBucket,
  messagingSenderId: config.srcmMessagingSenderId,
  appId: config.srcmAppId,
  databaseURL: config.srcmDatabaseURL,
}
const meUri = config.srcmApiUri
const srcmClientId = config.srcmClientId


if (firebase.apps.length === 0) {
  firebase.initializeApp(configData)
}

const fget = ls => ({
  firebase_uid: get(ls, ["user_firebase_uid"], ""),
  uid: get(ls, ["user_email"], false),
  id: get(ls, ["id"], ""),
  name: get(ls, ["name"], ""),
  email: get(ls, ["user_email"], false),
  phone: get(ls, ["mobile"], ""),
  abhyasiId: get(ls, ["ref"], false),
  country: get(ls, ["country", "name"], ""),
  country_id: get(ls, ["country", "id"], ""),
  state: get(ls, ["state", "name"], ""),
  state_id: get(ls, ["state", "id"], ""),
  city: get(ls, ["city"], ""),
  city_id: get(ls, ["city_id", "id"], ""),
  srcm_group: get(ls, ["srcm_group", "name"], ""),
  srcm_group_id: get(ls, ["srcm_group", "id"], ""),
  age: get(ls, ["age"], ""),
  isPrefect: get(ls, ["record_type"], false) === "t",
  abhyas_stage_type: get(ls, ["record_type"], false),
  isAtleastPractioner: ["n", "a", "t"].includes(
    get(ls, ["record_type"], false)
  ),

  address: compact([
    get(ls, ["street"], ""),
    get(ls, ["street2"], ""),
    get(ls, ["street3"], ""),
    get(ls, ["street4"], ""),
    get(ls, ["city"], ""),
    get(ls, ["postal_code"], ""),
  ])
    .filter(x => !!x && x !== "false")
    .join(" ")
    .trim(),

  streetAddress: compact([
    get(ls, ["street"], ""),
    get(ls, ["street2"], ""),
    get(ls, ["street3"], ""),
    get(ls, ["street4"], ""),
  ])
    .filter(x => !!x && x !== "false")
    .join(" ")
    .trim(),

  firstName: get(ls, "first_name", ""),
  lastName: get(ls, "last_name", ""),
  postalCode: get(ls, "postal_code", ""),
  photoUrl: get(ls, "photo_url", ""),
})

export const doLogout = () => {
  if (firebase.auth().currentUser) {
    localStorage.setItem("hfnAuthUserDetails", "")
    firebase.auth().signOut()
  } else {
    localStorage.setItem("hfnAuthUserDetails", "")
    firebase.auth().signOut()
  }
}

const AuthLogin = ({
  HeaderText = "Heartfulness Profile",
  onSignedIn,
  signInBtnText = "Sign In",
  signOutBtnText = "Sign Out",
}) => {
  const [show, setShow] = useState(false)
  const [userD, setUserD] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const signOut = () => {
    if (firebase.auth().currentUser) {
      // localStorage.setItem("hfnAuthUserDetails", "")
      // firebase.auth().signOut()
    }

    setUserD(null)
    setShow(false)
  }

  const showIt = React.useCallback(() => {
    const isLoggedIn = localStorage.getItem("hfnAuthUserDetails")
    if (!isLoggedIn) {
      setShow(true)
    } else {
      if (signInBtnText === "Plant a Saplings Sign In") {
        navigate("/planter-details")
      }
      if (signInBtnText === "Track a Saplings Sign In") {
        navigate("/trackaSapling")
      }
      if (signInBtnText === "Admin Login") {
        navigate("/admin/dashboardview")
      }
      setUserD(isLoggedIn)
    }
  }, [])
  const hideIt = React.useCallback(() => setShow(false), [])

  useEffect(() => {
    const uDetails = (typeof window !== 'undefined' && window.localStorage) ? localStorage.getItem("hfnAuthUserDetails") : null
    if (uDetails) {
      setUserD(uDetails)
    }
  }, [(typeof window !== 'undefined' && window.localStorage) ? localStorage : []])

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: "none",
    callbacks: {
      signInSuccessWithAuthResult: authResult => {
        const { user } = authResult
        if (user) {

          setLoading(true)
          user.getIdToken().then(authToken => {
            fetch(`${meUri}/api/v2/me/`, {
              method: "GET",
              headers: new Headers({
                Authorization: `Bearer ${authToken}`,
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "x-client-id": srcmClientId,
              }),
            }).then(res => {
              if (res.ok) {
                res.json().then(ud => {
                  const userDetails = {
                    srcmProfile: fget(ud.results[0]),
                    srcmToken: authToken,
                    displayName: user.displayName,
                  }
                  localStorage.setItem(
                    "hfnAuthUserDetails",
                    JSON.stringify(userDetails)
                  )
                  setUserD(userDetails)
                  onSignedIn(userDetails)
                  setLoading(false)
                })
              } else {
                setLoading(false)
                setShow(false)
              }
            })
          })
        }
      },
      signInFailure: e => console.log(e),
    },
  }

  const stopPropagate = e => {
    e.stopPropagation()
  }

  return (
    <React.Fragment>
      {show && !userD && (
        <div
          onClick={hideIt}
          className="auth-popup"
          style={{
            textAlign: "center",
            paddingTop: "10%",
            fontSize: "1.3em",
            fontWeight: 700,
            color: "#777",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: "100001",
            backdropFilter: "blur(1px)",
            background: "rgba(1, 1, 1, 0.8)",
          }}
        >
          <section
            onClick={stopPropagate}
            style={{
              margin: "auto",
              width: "fit-content",
              boxShadow: "0px 3px 6px #00000029",
              background: "white",
              minWidth: "320px",
            }}
          >
            <div
              style={{
                padding: "2rem",
              }}
            >
              <div style={{ marginBottom: "10px" }}>{HeaderText}</div>
              {isLoading ? (
                <Loader type="ThreeDots" color="#00BFFF" height={15} />
              ) : (
                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={firebase.auth()}
                />
              )}
            </div>
          </section>
        </div>
      )}
      {!userD ? (
        <button
          className="auth-btn"
          id="sign-in-btn"
          style={{
            border: "none",
            textDecoration: "none",
            cursor: "pointer",
            background: "transparent",
            padding: "10px 0px"
          }}
          onClick={() => showIt()}
        >
          {signInBtnText === "Plant a Saplings Sign In" ? <img src={plantSaplings} className='img-fluid' alt='Gifting Saplings' /> : <img src={TrackSaplings} className='img-fluid' alt='Gifting Saplings' />}
          {signInBtnText === "Admin Login" ? signInBtnText : null}
        </button>
      ) : (
        <button
          className="auth-btn"
          id="sign-out-btn"
          style={{
            border: "none",
            textDecoration: "none",
            cursor: "pointer",
            background: "transparent",
            padding: "10px 0px"
          }}
          onClick={() => signOut()}
        >
          {signOutBtnText === "Plant a Saplings Sign Out" ? <img src={plantSaplings} className='img-fluid' alt='Gifting Saplings' /> : <img src={TrackSaplings} className='img-fluid' alt='Gifting Saplings' />}
          {signInBtnText === "Admin Login" ? signInBtnText : null}
        </button>
      )}
    </React.Fragment>
  )
}

export default AuthLogin
